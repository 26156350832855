<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-overline">Поточна інкасація:</div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Дата інкасації:</span>
          <v-datetime-picker
            v-if="isEncashmentManual"
            v-model="editedItem.created_at"
            :textFieldProps="textFieldProps"
            :datePickerProps="datePickerProps"
            :timePickerProps="timePickerProps"
            timeFormat="HH:mm:ss"
            clear-text="очистити"
            ok-text="так"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
          <span v-else class="font-weight-medium">
            {{ editedItem.created_at | getShortDate }}
            <sup>{{ editedItem.created_at | getShortTime }}</sup>
          </span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Орендодавець:</span>
          <v-autocomplete
            v-if="isEncashmentManual"
            v-model="editedItem.balance_holder.id"
            @change="clear"
            :items="balanceHolders"
            item-text="label"
            item-value="value"
            hide-details="auto"
            dense
          ></v-autocomplete>
          <span v-else class="font-weight-medium">{{ editedItem.balance_holder.name }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">ID торгівельної точки:</span>
          <v-autocomplete
            v-if="isEncashmentManual"
            v-model="editedItem.service_point.id"
            :items="servicePoints"
            item-text="value"
            item-value="value"
            :disabled="!editedItem.balance_holder.id"
            hide-details="auto"
            dense
          ></v-autocomplete>
          <span v-else class="font-weight-medium">{{ editedItem.service_point.id }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Адреса торгівельної точки:</span>
          <v-autocomplete
            v-if="isEncashmentManual"
            v-model="editedItem.service_point.id"
            :items="servicePoints"
            item-text="label"
            item-value="value"
            :disabled="!editedItem.balance_holder.id"
            hide-details="auto"
            dense
          ></v-autocomplete>
          <span v-else class="font-weight-medium">
            {{ editedItem.service_point.address.city }}, {{ editedItem.service_point.address.street }},
            {{ editedItem.service_point.address.building_number }}
          </span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Термінал:</span>
          <v-autocomplete
            v-if="isEncashmentManual"
            v-model="editedItem.terminal.id"
            @change="selectTerminal(editedItem.terminal.id)"
            :items="terminals"
            item-text="label"
            item-value="value"
            :disabled="!editedItem.service_point.id"
            hide-details="auto"
            dense
          ></v-autocomplete>
          <span v-else class="font-weight-medium">T {{ editedItem.terminal.id }}</span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Неспалювальний лічильник:</span>
          <v-text-field v-model.number="editedItem.total_counter" class="ml-2" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Днів після попередньої інкасації:</span>
          <span class="font-weight-medium">
            {{ isEditCard ? editedItem.days_after_prev_encashment : daysAfterPrevEncashment }}
          </span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Інкасатор:</span>
          <v-select v-model="editedItem.collector.id" :items="staff" item-value="id" dense>
            <template v-slot:selection="{ item }">{{ item.user.first_name }} {{ item.user.last_name }}</template>
            <template v-slot:item="{ item }">{{ item.user.first_name }} {{ item.user.last_name }}</template>
          </v-select>
        </div>

        <v-divider class="my-4"></v-divider>

        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Сума інкасації, грн.:</span>
          <span class="font-weight-medium"> {{ editedItem.cash_sum }} </span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Ручний перерахунок, грн.:</span>
          <v-text-field v-model.number="editedItem.actual_sum" class="ml-2" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Технічній вброс, грн.:</span>
          <v-text-field v-model.number="editedItem.technical_payment" class="ml-2" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">
            Відрахування, грн.:
            <sup>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                </template>
                <span>Відрахування на з/п, матеріали, прибирання, грн.</span>
              </v-tooltip>
            </sup>
          </span>
          <v-text-field v-model.number="editedItem.payout" class="ml-2" dense hide-details></v-text-field>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Різниця, грн.:</span>
          <span
            :class="[{ 'alert-difference': editedItem.difference > 50 || editedItem.difference < -50 }, 'font-weight-medium']"
          >
            {{ difference }}
          </span>
        </div>
        <div class="d-flex align-baseline custom-field">
          <v-textarea
            v-model="editedItem.comment"
            class="mt-5"
            height="80"
            hint="Коментар до перерахунку"
            label="Коментар"
            outlined
            no-resize
            clearable
          ></v-textarea>
        </div>
        <div class="d-flex align-baseline custom-field">
          <span class="mr-2">Заповнив:</span>
          <span v-if="editedItem.creator" class="font-weight-medium">
            {{ editedItem.creator.first_name }} {{ editedItem.creator.last_name }}
          </span>
        </div>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" sm="6" :class="{ 'text--disabled': !prevEncashment.id }">
        <div class="text-overline">Попередня інкасація:</div>
        <EncashmentInfo :encashment="prevEncashment" :current-company-id="currentCompanyId" />
      </v-col>
    </v-row>

    <v-divider v-if="isEncashmentManual" class="my-4"></v-divider>

    <v-row v-if="isEncashmentManual">
      <v-col cols="12" sm="6">
        <div class="text-overline">Купюри, що були прийняти в період інкасації:</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Номінал купюри</th>
                <th class="text-left">Кількість з інкасації, шт.</th>
                <th class="text-left">Перерахунок (за потреби) , шт.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in NOTES" :key="item.property">
                <td>{{ item.title }}</td>
                <td>{{ editedItem.notes_and_coins[0].notes[item.property] }}</td>
                <td>
                  <v-text-field
                    v-model.number="editedItem.actual_notes_and_coins[0].notes[item.property]"
                    class="ml-2"
                    type="number"
                    dense
                    hide-details
                    outlined
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { NOTES } from '@/const/encashment-coins.enum'
import { ENCASHMENT_SCHEMA } from '@/const/apiSchemas'
import ConvertDate from '@/mixins/convertDate'
import CloneDeep from '@/mixins/cloneDeep'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import { mapGetters } from 'vuex'
import { daysDifference } from '@/helpers/datesDifference'
import EncashmentInfo from '@/components/finance/encashments/EncashmentInfo.vue'

export default {
  name: 'EncashmentCardCRU',
  components: { EncashmentInfo },

  mixins: [ConvertDate, CloneDeep],

  props: {
    encashment: {
      type: Object,
      required: true,
      default: () => {},
    },
    prevEncashment: {
      type: Object,
      required: true,
      default: () => {},
    },
    staff: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentCompanyId: {
      required: true,
      default: null,
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    isEditCard: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  data: () => ({
    ENCASHMENT_SCHEMA: ENCASHMENT_SCHEMA,
    NOTES: NOTES,
    editedItem: {},
    textFieldProps: {
      hideDetails: 'auto',
      dense: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
  }),

  computed: {
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter']),

    defaultItem() {
      return !isObjectEmpty(this.encashment)
        ? this.cloneObjectDeep(this.encashment)
        : this.cloneObjectDeep(this.ENCASHMENT_SCHEMA)
    },
    isEncashmentManual() {
      if (this.$route.hash === '#create') return true
      return this.encashment.manual_recalculation
    },
    difference() {
      return this.editedItem.actual_sum + this.editedItem.payout + this.editedItem.technical_payment - this.editedItem.cash_sum
    },
    daysAfterPrevEncashment() {
      return daysDifference(this.prevEncashment.created_at, this.editedItem.created_at)
    },
    balanceHolders() {
      return this.getBalanceHolderFilter
    },
    servicePoints() {
      if (!this.editedItem.balance_holder.id) return []
      return this.getServicePointFilter.filter((item) => this.editedItem.balance_holder.id === item.parent)
    },
    terminals() {
      if (!this.editedItem.service_point.id) return []
      return this.getTerminalFilter.filter((item) => this.editedItem.service_point.id === item.parent)
    },
  },

  watch: {
    difference(val) {
      this.editedItem.difference = val
    },
    isDataSaved(val) {
      if (val) {
        if (this.isEditCard) this.optimizeSchema()
        this.$emit('saveData', this.editedItem)
      }
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.editedItem = { ...this.defaultItem }
      if (!this.editedItem?.collector) this.editedItem.collector = {}
    },
    clear() {
      this.editedItem.service_point.id = null
      this.editedItem.terminal.id = null
    },
    selectTerminal(terminalId) {
      if (!this.isEditCard) this.$emit('selectTerminal', terminalId)
    },
    optimizeSchema() {
      Object.keys(this.editedItem.balance_holder).forEach((property) => {
        if (property !== 'id') delete this.editedItem.balance_holder[property]
      })
      Object.keys(this.editedItem.service_point).forEach((property) => {
        if (property !== 'id') delete this.editedItem.service_point[property]
      })
      Object.keys(this.editedItem.terminal).forEach((property) => {
        if (property !== 'id') delete this.editedItem.terminal[property]
      })
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
}
.alert-difference {
  color: #f44336;
}
</style>
